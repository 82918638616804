import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import '../fonts/fonts.css'

const Header = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
`

const Logo = styled.h1`
  font-size: 1.5em;
  & > a {
    font-family: 'Bagnard Regular';
    text-decoration: none;
    color: black;
  }
`

const Nav = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
`

const NavItem = styled.li`
  display: inline-block;
  margin-left: 1em;
  & > a {
    text-decoration: none;
    color: black;
  }
`

export default props => (
  <Header>
    <Logo>
      <Link to="/">franki</Link>
    </Logo>
    <Nav>
      <NavItem>
        <Link to="/">Work</Link>
      </NavItem>
      <NavItem>
        <Link to="/about">About</Link>
      </NavItem>
    </Nav>
  </Header>
)
