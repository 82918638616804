import React from 'react'
import Header from './Header'
import styled from "@emotion/styled"

const Layout = styled.section`
  padding: 2em;
`

export default ({ location, title, children }) => (
  <Layout>
    <Header />
    {children}
  </Layout>
)


